<template>
    <v-app class="desktopLayoutClass">
        <div class="successScreenStyle">
            <img src="https://s3iconimages.mymedicine.com.mm/successIconPoppers.gif" style="width: 85px;">
            <h3 style="font-size: 20px !important;">Appointment Booked Successfully!</h3>
            <p>Your nurse appointment is confirmed</p>
            <div class="boxStyle" v-if="!uploadSuccess">
                <div class="uploadDescription"> 
                    <img src="https://s3iconimages.mymedicine.com.mm/uploadFileIcon.svg"/>
                    <div>Upload Medical Report</div>
                </div>
                <div v-if="selectedFileList.length == 0" style="display: flex; justify-content: center; flex-direction: column; align-items: center;">
                    <img src="https://s3iconimages.mymedicine.com.mm/uploadFilesLargeImage.svg"/>
                    <p>Upload prescription, reports, or other medical records from your device.</p>
                </div>
                <div v-else style="margin-bottom: 10px;">
                    <div class="pickedFileBox" v-for="(item, index) in selectedFileList" :key="index">
                        <div>
                            <img :src="calculateSRCURL(item)"/>
                        </div>
                        <div style="display: flex; flex-direction: column; align-items: flex-start; text-align: left;" >
                            <div>{{ item.name }}</div>
                            <div>{{ (item.size / 1024 / 1024).toFixed(2) }} MB</div>
                        </div>

                        <img  class="closeButton" src="https://s3iconimages.mymedicine.com.mm/closeCircular.svg" @click="removeFromArray(item)"/>
                    </div>
                </div>
                <div style="display: flex; gap: 10px;">
                    <v-btn v-if="isUploadButtonAllowed"
                        color="#48ACEF"
                        large
                        outlined
                        raised
                        @click="openFilePicker"
                        :disabled="submitButtonLoading"
                    >Upload</v-btn>     
                    <v-btn
                        color="primary"
                        large
                        raised
                        :disabled = "selectedFileList.length === 0"
                        :loading="submitButtonLoading"
                        @click="uploadToS3"
                    >Submit</v-btn>     
                </div> 
                <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" accept=".pdf, image/*">
            </div>
            <div v-else>
                <h3 style="font-size: 20px !important;">Uploaded Successfully!</h3>
            </div>
            <v-btn color="#48ACEF" class="primary" width="100%" @click="goToMyAppointments()">Go To My Appointments</v-btn>
            <v-btn outlined color="#48ACEF" width="100%" @click="gotoHome()">Go Home</v-btn>
        </div>
    </v-app>
</template>
<script>
import { mixinFunctions } from '../../mixins/index';
import axios from 'axios';
import { axios_auth_instance_nurseBooking } from '../../utils/axios_utils';
export default {
    mixins:[mixinFunctions],
    data () {
        return {
            selectedFileList: [],
            isUploadButtonAllowed: true,
            finalFileList: [],
            submitButtonLoading: false,
            uploadSuccess: false,
            bookingID: null,
        }
    },
    mounted() {
        this.$store.dispatch('cleanNurseBookingValues');
        this.bookingID = this.$route.params.bookingID;
        this.userPageViewTable('NBAOrderSuccessPage');
    },
    methods:{
        calculateSRCURL(file) {
            if (this.isPDF(file)) {
                return 'https://s3iconimages.mymedicine.com.mm/pdfIcon.svg';
            } else {
                return 'https://s3iconimages.mymedicine.com.mm/imageIcon.svg';
            }
        },
        removeFromArray(removeItem) {
            this.isUploadButtonAllowed = true;
            this.selectedFileList = this.selectedFileList.filter((item) => (item.name !== removeItem.name))
        },
        gotoHome() {
            if (!window.xm) {
				this.$router.push({
					name: 'CustomerHomeScreen'
				});
			} else {
                this.$router.push({ path: '/' });
            }
        },
        goToMyAppointments(){
            this.$router.push({path: '/appointments'})
        },
        openFilePicker() {
      // Trigger the file input click event
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            // Handle the selected file here
            const selectedFile = event.target.files[0];
            if (!this.isAllowedFileType(selectedFile)) {
                alert('Only image and pdf allowed')
                return;
            }
            if ((selectedFile.size/1024/1024) > 10) {
                alert('Please upload file of size upto 10 MB maximum');
            }
            if (this.selectedFileList.length == 2) {
                this.isUploadButtonAllowed = false;   
            } 
            this.selectedFileList.push(selectedFile);
            // You can perform additional actions with the selected file, such as uploading or processing it.
        },
        isImage(file) {
      return file.type.startsWith('image/') || /\.(jpg|jpeg|png|gif)$/i.test(file.name);
        },
        isPDF(file) {
        return file.type === 'application/pdf' || /\.pdf$/i.test(file.name);
        },
        isAllowedFileType(file) {
      // Check if the file is an image or PDF based on its MIME type or file extension
        return (
            file.type.startsWith('image/') || // Check if it's an image
            file.type === 'application/pdf' || // Check if it's a PDF
            /\.(pdf|jpg|jpeg|png|gif)$/i.test(file.name) // Check file extension
        );
        },
        async uploadToS3() {
            this.submitButtonLoading = true;
        try {
            let databaseFileUpdateList = [];
            let fileMap = {};
            this.finalFileList = this.selectedFileList.map((item) => {

                let s3LocalFileName  = `${Date.now()}_${item.name}`;
                fileMap[s3LocalFileName] = item;
                return {
                    s3FileName: s3LocalFileName,
                    s3FileType: item.type,
                    fileType: this.isPDF(item) ? 'PDF' : 'IMAGE',
                    userOPTFileName: item.name,
                }
            });
            let presignedURLMap = null;
            try {
                presignedURLMap= await axios_auth_instance_nurseBooking.post('customer/presignedWrite', {
                fileList: this.finalFileList
                });
                for (let i = 0; i<this.finalFileList.length; i++) {
                    let fields = presignedURLMap.data.data[this.finalFileList[i].s3FileName].presignedData.fields;
                    let url = presignedURLMap.data.data[this.finalFileList[i].s3FileName].presignedData.url;
                        let post_body = {
                        bucket: fields.bucket,
                        ...fields,
                        'Content-Type': this.finalFileList[i].s3FileType,
                        file: fileMap[this.finalFileList[i].s3FileName]
                        };
                        const formData = new FormData();
                        for(const name in post_body)
                        {
                        formData.append(name,post_body[name]);
                        }
                        try {
                            await axios.post(url, formData);
                            databaseFileUpdateList.push(this.finalFileList[i]);
                        } catch (error) {
                            console.log(error);
                        }
                }
                this.uploadSuccess = true;
                axios_auth_instance_nurseBooking.post('customer/attachment', {
                                bookingID: this.bookingID,
                                fileList: databaseFileUpdateList
                })
            } catch (error) {
                console.log(error);
                alert('Upload unsuccessfull');
                return;
            }

            
        } catch (error) {
            console.log(error);
        } finally {
            this.submitButtonLoading = false;
        }
    },
    },
    
}
</script>
<style>
.successScreenStyle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 20px 24px 20px 24px;
    width: 100%;
}

.boxStyle {
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    /* width: 100% !important; */
    align-items: center;
    /* align-self: stretch; */
    background: #FFF;
    font-weight: 600 !important;
    /* margin: 10px; */
}
.uploadDescription {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    align-self: stretch;
    color: var(--Gray-1, #333);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 10px;
}
.pickedFileBox {
    position: relative;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px dashed var(--Grays-Gray-4, #BDBDBD);
    width: 360px;
    margin: 10px 0;
}
.closeButton {
    position: absolute;
    right: -11px;
    top: -8.83px;
    cursor: pointer;
}
</style>